<template>
    <ModalFields v-if="getModalFieldsGoogleSheets.show"
                 @dispatch-close-modal="close()"
                 @dispatch-save-fields="saveFields($event)"
                 @dispatch-change-select-field="changeFieldSelection($event)"
                 :modal-data="getModalFieldsGoogleSheets.data"
                 :ref="'modalFields'">
        <template v-slot:selected-fields>
            <div v-if="$refs.modalFields !== undefined & $refs.modalFields !== null">
                <ul class="fields-list">
                    <li v-for="(field, index) in $refs.modalFields.selectedFields"
                        :key="field.id"
                        draggable="true"
                        @dragstart="dragFieldStart(index)"
                        @drop="dropField(index)"
                        @dragover.prevent>
                        <input type="checkbox" id="check-20-1" name="check-20-1">
                        <label for="check-20-1" :class="['entity-icon', field.field_scope.icon_class]">
                            {{ field.label }}
                        </label>
                        <div class="to-right">
                        <span class="field-unit-type">
                            <span :class="['field-unit-type_icon', field.datatype]"></span>
                            {{ field.datatype }}
                        </span>
                            <span class="info-icon"></span>
                            <span @click="unSelectField(field.name, field.field_scope.id, field.id)"
                                  class="trash-icon"></span>
                        </div>
                    </li>
                </ul>
                </div>
        </template>
<!--        <template v-slot:field-list="{scope}">-->
<!--            <li v-for="field in $refs.modalFields.filteredFields[scope.id].fields"-->
<!--                :key="field.id"-->
<!--                >-->
<!--                <input-->
<!--                        type="checkbox"-->
<!--                        :id="'check-'+field.id"-->
<!--                        :name="'check-'+field.id"-->
<!--                        @input="changeSelectField(scope.id, field)"-->
<!--                        :checked="isFieldSelected(scope.id, field)"-->
<!--                >-->
<!--                <label :for="'check-'+field.id"-->
<!--                       :class="['entity-icon', scope.icon_class]">-->
<!--                    {{ field.label }}-->
<!--                </label>-->
<!--                <div class="to-right">-->
<!--                        <span class="field-unit-type">-->
<!--                            <span :class="['field-unit-type_icon', field.datatype]"></span>-->
<!--                            {{ field.datatype }}-->
<!--                        </span>-->
<!--                    <span class="info-icon"></span>-->
<!--                </div>-->
<!--            </li>-->
<!--        </template>-->
    </ModalFields>
</template>
<script>

import ModalFields from "@/components/modal/modal-fields/index.vue";
import {mapGetters} from "vuex";
import _ from "lodash";
import {getFieldsDependenciesForField} from "@/services/field-scope";
import {getModalFieldsGoogleSheets} from "@/store/getters";

export default {
    name: 'GoogleSheetDataShareModalFields',
    components: {ModalFields},
    data() {
        return {}
    },
    computed: {
        ...mapGetters(['getModalFieldsGoogleSheets', 'getInsightsConstants', 'getCurrentObject']),
    },
    created() {
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                this.close();
            }
        });
    },
    methods: {
        dragFieldStart(index) {
            this.draggedIndex = index;
        },
        dropField(index) {
                const movedItem = this.$refs.modalFields.selectedFields.splice(this.draggedIndex, 1)[0];
                this.$refs.modalFields.selectedFields.splice(index, 0, movedItem);
                this.draggedIndex = null;
            },
        close() {
            _.set(this.getModalFieldsGoogleSheets, 'show', false);
        },
        saveFields(fieldsByScope) {
            _.forEach(this.$refs.modalFields.selectedFields, function(field, index) {
                _.find(fieldsByScope[field.field_scope.id].selected, function(selectedField) {
                    if (field.id === selectedField.id){
                        _.set(selectedField, 'order', index);
                    }
                })
            });
            _.set(this.getModalFieldsGoogleSheets, 'show', false);
            _.set(this.getModalFieldsGoogleSheets, 'data', fieldsByScope);
        },
        unSelectField(fieldName, scopeId, fieldId) {
            this.$refs.modalFields.fieldsByScope[scopeId].selected = _.filter(this.$refs.modalFields.fieldsByScope[scopeId].selected, field => field.name !== fieldName);
            this.$refs.modalFields.selectedFields = _.filter(this.$refs.modalFields.selectedFields, field => field.id !== fieldId);
        },
        async changeFieldSelection(value) {
            let scopeId = value.scopeId;
            let field = value.field;
            if (this.$refs.modalFields.isFieldSelected(scopeId, field)) {
                this.unSelectField(field.name, scopeId, field.id);
            } else {
                this.$refs.modalFields.fieldsByScope[scopeId].selected.push(field);
                this.$refs.modalFields.selectedFields.push(field)
            }
        }
    }
}
</script>
<style scoped>

</style>