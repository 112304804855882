import AxiosWrapper from '@/helpers/axios-wrapper'


/**
 * @returns Promise
 * @param platform
 */
export function listPlatformAccounts(platform) {
    const params = {network: platform};
    return AxiosWrapper('/clients/platform_accounts/', {params});
}

/**
 * @returns Promise
 * @param account_id
 * @param platform
 */
export function getCampaignsByAccount(account_id, platform) {
    const params = {account_id: account_id, network: platform};
    return AxiosWrapper('/clients/platform_items/campaigns/', {params});
}

/**
 * @returns Promise
 * @param account_id
 * @param platform
 * @param status
 * @param search_term
 */
export function getCampaignsByAccountAndStatusAndSearchTerm(account_id, platform, status, search_term) {
    const params = {account_id: account_id, network: platform, status: status, search_term: search_term};
    return AxiosWrapper('/clients/platform_items/campaigns_by_status_search_term/', {params});
}

/**
 * @returns Promise
 * @param account_id
 * @param campaign_id
 * @param platform
 */
export function getAdGroupsByCampaign(account_id, campaign_id, platform) {
    const params = {account_id: account_id, campaign_id: campaign_id, network: platform};
    return AxiosWrapper('/clients/platform_items/ad_groups/', {params});
}

/**
 * @returns Promise
 */
export function detail(platformName) {
    const params = {platform: platformName}
    return AxiosWrapper('/clients/elements/detail/', {params});
}

/**
 * @returns Promise
 */
export function automaticApps(accountId, network) {
    const params = {accountId: accountId, network: network}
    return AxiosWrapper('/clients/account/automatic_apps/', {params});
}
/**
 * @returns Promise
 */
export function alertApps(accountId) {
    const params = {accountId: accountId}
    return AxiosWrapper('/clients/account/alert_apps/', {params});
}




/**
 * @returns Promise
 */
export function store(formData) {
    return AxiosWrapper('/elements/store/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function remove(formData) {
    return AxiosWrapper('/elements/remove/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}
