<template>
    <div v-if="getUser && getDrawerGroupCampaigns.show"
         :class="['ap2-modal', 'ap2-modal-md', 'ap2-modal-is-drawer', getDrawerGroupCampaigns.show ? 'open' : null]"
         id="drawer-test">
        <div class="modal-wrapper ">
            <div class="ap2-modal-header">
                <div class="modal-header-title-wrapper">
                    <div class="modal-header-reference">
                        {{ appInClientAccount.account.name }}
                    </div>
                    <div class="modal-header-title">
                        {{ group.name }}
                    </div>
                </div>
                <div class="modal-header-close close-modal-demo">
                    <svg @click="closeDrawer()"
                         height="1em"
                         viewBox="0 0 384 512"
                         xmlns="http://www.w3.org/2000/svg">
                        <path class="svg-fill"
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                    </svg>
                </div>
            </div>
            <div class="ap2-modal-content ps">
                <perfect-scrollbar>
                    <toggle-collapse
                            @dispatch-selected-option="changeSelectedStatus"
                            :name="'Choose a campaign status'"
                            :options="getElementsViewModeList()"
                            :options-type="'radius'"
                            :selected-option="selectedCampaignsStatus"
                    />
                    <div @click="setCollapse()"
                         :class="['drawer-title-section', 'ap2-toggle-collapse', getCollapseShow ? 'show' : null]"
                         data-ap2-target="#tst-options">
                        <span class="ap2-toggle-collapse-title">
                            Filter by campaign name
                        </span>
<!--                        <span class="ap2-toggle-collapse-chevron">-->
<!--                            <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">-->
<!--                                <path class="svg-fill"-->
<!--                                      d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path>-->
<!--                            </svg>-->
<!--                        </span>-->
                    </div>
                    <div class="pull-right mt-2">
                            <div class="search-extensible active">
                                <input :value="searchValue"
                                       @input="searchValue=$event.target.value"
                                       type="text"
                                       class="input active"
                                       placeholder="Search">
                                <span v-if="searchValue"
                                      @click="resetSearchValue()"
                                      class="icon"
                                      :style="['mask-image: url(' + plusSolid + ')', 'mask-repeat: no-repeat',
                      'mask-position: center', 'mask-size: 11px', 'background-color: var(--overAppContainerColorMedium)',
                      'transform: rotate(45deg)']"></span>
                                <span v-else class="icon" :style="'-webkit-mask-image: url(' + search + ')'"></span>
                            </div>
                        </div>
                    <div class="mt-4" :tooltip="(searchValue === null || searchValue === undefined || searchValue === '') ? 'You must input a search value' : null">
                        <button @click="searchCampaigns()"
                                type="button"
                                class="ap2-btn btn btn-primary drawer-search-button"
                                :disabled="searchValue === null || searchValue === undefined || searchValue === ''"
                                >
                            Search campaigns
                        </button>
                    </div>
                    <div v-if="campaigns.length > 0 || (campaigns.length === 0 && searched)">
                        <div
                                class="drawer-title-section ap2-toggle-collapse show"
                                data-ap2-target="#tst-options">
                        <span class="ap2-toggle-collapse-title">
                            Campaigns
                        </span>
                            <span class="ap2-toggle-collapse-chevron">
                            <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                <path class="svg-fill"
                                      d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path>
                            </svg>
                        </span>
                        </div>
                    </div>
                    <div id="tst-options" class="collapsable-element show">
                        <div v-if="campaigns.length > 0" class="form-group ap2-form-group mt-2">
                            <div class="ap2-form-control-has-icon left is-search">
                                <input :value="filterItemsText"
                                       @input="filterItemsText=$event.target.value"
                                       class="form-control ap2-form-control"
                                       placeholder="Search"
                                       type="text">
                                <span class="icon" :style="'-webkit-mask-image: url(' + search + ')'"></span>
                            </div>
                        </div>
                        <div v-if="loading" class="text-center">
                            <i class="fas fa-spinner fa-spin"></i> Loading campaigns
                        </div>
                        <ul v-else-if="campaigns.length > 0 && !loading" class="input-selector-list has-visible-input">
                            <li v-for="item in getItemsWithFilter"
                                class="form-label"
                                :key="item.id">
                                <input v-model="itemsSelected"
                                       type="checkbox"
                                       :id="item.id"
                                       :value="item">
                                <label :for="item.id">
                                    {{ item.name }}
                                </label>
                            </li>
                        </ul>
                        <p v-else-if="campaigns.length === 0 && searched && !loading" class="mt-2 center-paragraph">
                            No campaigns found for this criteria
                        </p>
                        <p v-else-if="tooManyResults" class="mt-2 center-paragraph">
                            Too many results for this criteria. Please, try with another criteria.
                        </p>
                    </div>
                </perfect-scrollbar>
            </div>
            <div class="ap2-modal-footer modal-controls">
                <div class="modal-controls">
                    <button @click="cancelButtonActions()"
                            type="button"
                            class="ap2-btn btn btn-dark close-modal-demo">
                        Cancel
                    </button>
                    <button @click="setSelectedItems()"
                            type="button"
                            class="ap2-btn btn btn-success close-modal-demo">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import _ from "lodash";
    import search from '@/assets/images/magnifying-glass-solid.svg';
    import {getCampaignsByAccount} from "@/services/account";
    import {getCampaignsByAccountAndStatusAndSearchTerm} from "@/services/account";
    import {PerfectScrollbar} from 'vue3-perfect-scrollbar';
    import ToggleCollapse from "@/components/commons/toggle-collapse/index.vue";
    import enabled from "@/assets/images/play-solid.svg";
    import disabled from "@/assets/images/pause-solid.svg";
    import plusSolid from "@/assets/images/plus-solid.svg";
    import {getDrawerGroupCampaigns} from "@/store/getters";

    export default {
        name: "AppInClientAccountGroupCampaignsDrawer",
        components: {ToggleCollapse, PerfectScrollbar},
        data() {
            return {
                 search: search,
                appInClientAccount: null,
                campaigns: [],
                itemsSelected: [],
                filterItemsText: null,
                group: null,
                searched: null,
                getCollapseShow: true,
                selectedCampaignsStatus: 'Active',
                searchValue: null,
                plusSolid: plusSolid,
                loading: false,
                tooManyResults: false
            }
        },
        computed: {
            ...mapGetters(['getDrawerGroupCampaigns', 'getConstants', 'getUser']),
            getItemsWithFilter() {
                let items = [];
                if (this.filterItemsText) {
                    _.forEach(this.campaigns.filter(element => _.toLower(element.name).includes(_.toLower(this.filterItemsText))), item => {
                        items.push(item);
                    })
                } else {
                    _.forEach(this.campaigns, item => {
                        items.push(item);
                    })
                }
                return items
            },
        },
        mounted() {
            this.$store.watch(state => state.drawerGroupCampaigns, (value) => {
                if (value.show) {
                    this.group = value.data.group;
                    this.appInClientAccount = value.data.appInClientAccount;
                    // this.loadCampaigns(value.data.itemsSelected);
                    this.itemsSelected = value.data.itemsSelected;
                }
            }, {deep: true});
        },
        methods: {
            closeDrawer() {
                _.set(this.getDrawerGroupCampaigns, 'show', false);
                this.itemsSelected = [];
                this.campaigns = [];
                this.appInClientAccount = null;
                this.filterItemsText = null;
                this.group = null;
                this.searched = false;
                this.loading = false;
                this.searchValue = null
                this.selectedCampaignsStatus = 'Active'
                this.$emit('dispatch-close-drawer')
            },
            setSelectedItems() {
                _.set(this.getDrawerGroupCampaigns, 'result', this.itemsSelected);
                this.closeDrawer()
            },
            cancelButtonActions() {
                this.itemsSelected = this.getDrawerGroupCampaigns.data.itemsSelected;
                this.closeDrawer();
            },
            getElementsViewModeList() {
                return this.getConstants.CAMPAIGNS_STATUS
            },
            changeSelectedStatus(status) {
                this.selectedCampaignsStatus = status;
            },
            setCollapse() {
                this.getCollapseShow ? this.getCollapseShow = false : this.getCollapseShow = true
            },
            resetSearchValue() {
                this.searchValue = null;
            },
            async searchCampaigns() {
                this.loading = true;
                this.tooManyResults = false;
                let response;

                try {
                    response = await getCampaignsByAccountAndStatusAndSearchTerm(this.appInClientAccount.account.id,
                            this.appInClientAccount.account.network, this.selectedCampaignsStatus, this.searchValue)
                } catch (e) {
                    console.log("ERROR: ", e);
                    return;
                }
                this.campaigns = []
                if (response.data.result !== -1) {
                    _.forEach(response.data.result, campaign => {
                        if (_.find(this.itemsSelected, item => item.id === campaign.id) === undefined) {
                            this.campaigns.push(campaign)
                        }
                    });
                    this.itemsSelected = [];
                    this.searched = true;
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.tooManyResults = true;
                }
            },
        }
    }
</script>