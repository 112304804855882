<template>
    <div v-if="getUser && getDrawerClientAccounts.show"
         :class="['ap2-modal', 'ap2-modal-md', 'ap2-modal-is-drawer', getDrawerClientAccounts.show ? 'open' : null]"
         id="drawer-test">
        <div class="modal-wrapper ">
            <div class="ap2-modal-header">
                <div class="modal-header-title-wrapper">
                    <div class="modal-header-reference">
                        {{ getCurrentObject.object.name }}
                    </div>
                    <div class="modal-header-title">
                        New Account(s)
                    </div>
                </div>
                <div class="modal-header-close close-modal-demo">
                    <svg @click="closeAccountsDrawer()"
                         height="1em"
                         viewBox="0 0 384 512"
                         xmlns="http://www.w3.org/2000/svg">
                        <path class="svg-fill"
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                    </svg>
                </div>
            </div>
            <div class="ap2-modal-content ps">
                <perfect-scrollbar>
                    <toggle-collapse
                            @dispatch-selected-option="changeSelectedPlatform"
                            :name="'Platforms'"
                            :options="getPlatformsList()"
                            :options-type="'icon'"
                            :selected-option="selectedPlatform"
                    />
                    <div @click="setCollapse()"
                         :class="['drawer-title-section', 'ap2-toggle-collapse', getCollapseShow ? 'show' : null]"
                         data-ap2-target="#tst-options">
                    <span class="ap2-toggle-collapse-title">
                        Accounts
                    </span>
                        <span class="ap2-toggle-collapse-chevron">
                        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                            <path class="svg-fill"
                                  d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path>
                        </svg>
                    </span>
                    </div>
                    <div id="tst-options" :class="['collapsable-element', getCollapseShow ? 'show' : null]">
                        <div v-if="accounts.length > 0" class="form-group ap2-form-group mt-2">
                            <div class="ap2-form-control-has-icon left is-search">
                                <input :value="filterItemsText"
                                       @input="filterItemsText=$event.target.value"
                                       class="form-control ap2-form-control"
                                       placeholder="Search"
                                       type="text">
                                <span class="icon" :style="'-webkit-mask-image: url(' + search + ')'"></span>
                            </div>
                        </div>
                        <div v-if="loading" class="text-center">
                            <i class="fas fa-spinner fa-spin"></i> Loading {{ selectedPlatform }} accounts
                        </div>
                        <ul v-if="accounts.length > 0 & !loading" class="input-selector-list has-visible-input">
                            <li v-for="item in getItemsWithFilter"
                                class="form-label"
                                :key="item.id">
                                <input v-model="itemsSelected"
                                       type="checkbox"
                                       :id="item.id"
                                       :value="item.name">
                                <label :for="item.id">
                                    {{ item.name }}
                                </label>
                            </li>
                        </ul>
                        <p v-if="accounts.length === 0 && !loading" class="mt-2">
                            You must select a platform first
                        </p>
                    </div>
                </perfect-scrollbar>
            </div>
            <div class="ap2-modal-footer modal-controls">
                <div class="modal-controls">
                    <button @click="cancelButtonActions()"
                            type="button"
                            class="ap2-btn btn btn-dark close-modal-demo">
                        Cancel
                    </button>
                    <button @click="setSelectedItems()"
                            type="button"
                            class="ap2-btn btn btn-success close-modal-demo">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import _ from "lodash";
    import search from '@/assets/images/magnifying-glass-solid.svg';
    import ToggleCollapse from "@/components/commons/toggle-collapse/index.vue";
    import Google from '@/assets/images/google.svg';
    import Facebook from '@/assets/images/facebook.svg';
    import Twitter from '@/assets/images/twitter-x.svg';
    import Dv360 from '@/assets/images/DV360.svg';
    import TikTok from '@/assets/images/tiktok.svg';
    import {getDrawerClientAccounts} from "@/store/getters";
    import {listPlatformAccounts} from "@/services/account";
    import {PerfectScrollbar} from 'vue3-perfect-scrollbar';

    export default {
        name: "AccountsDrawer",
        components: {ToggleCollapse, PerfectScrollbar},
        computed: {
            ...mapGetters(['getDrawerClientAccounts', 'getConstants', 'getCurrentObject', 'getUser']),
            getItemsWithFilter() {
                let items = [];
                if (this.filterItemsText) {
                    _.forEach(this.accounts.filter(element => _.toLower(element.name).includes(_.toLower(this.filterItemsText))), item => {
                        items.push(item);
                    })
                } else {
                    _.forEach(this.accounts, item => {
                        items.push(item);
                    })
                }
                return items
            },
        },
        // watch: {
        //     dataSelected: {
        //         handler: function (newVal) {
        //             this.itemsSelected = newVal;
        //         }
        //     }
        // },
        data() {
            return {
                filterItemsText: null,
                search: search,
                selectedPlatform: null,
                accounts: [],
                itemsSelected: [],
                getCollapseShow: true,
                loading: false,
            }
        },
        methods: {
            closeAccountsDrawer() {
                _.set(this.getDrawerClientAccounts, 'show', false);
                this.itemsSelected = [];
                this.accounts = false;
                this.selectedPlatform = null;
                this.filterItemsText = null;
                this.getCollapseShow = true;
                this.$emit('dispatch-close-drawer')
            },
            setSelectedItems() {
                _.set(this.getDrawerClientAccounts, 'result', _.uniqBy(this.getSelectAccountsObjects(), 'id'));
                this.closeAccountsDrawer()
            },
            cancelButtonActions() {
                this.itemsSelected = this.getDrawerClientAccounts.data.itemsSelected;
                this.closeAccountsDrawer();
            },
            changeSelectedPlatform(platform) {
                this.selectedPlatform = platform;
                this.accounts = [];
                this.loading = true;
                this.loadAccounts(platform);
            },
            setCollapse() {
                this.getCollapseShow ? this.getCollapseShow = false : this.getCollapseShow = true
            },
            getPlatformsList() {
                return [
                    {'name': 'Facebook', 'icon': Facebook},
                    {'name': 'Google', 'icon': Google},
                    {'name': 'TikTok', 'icon': TikTok},
                    {'name': 'Twitter', 'icon': Twitter},
                    {'name': 'DV360', 'icon': Dv360}
                ]
            },
            async loadAccounts(platform) {
                let response;
                try {
                    response = await listPlatformAccounts(platform);
                } catch (e) {
                    return;
                }
                if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: 'Error recovering platform accounts',
                        type: 'error',
                        position: 'top-right',
                        duration: 2000,
                    });
                    return;
                }
                _.forEach(response.data.result, account => {
                    if (!_.includes(this.getDrawerClientAccounts.data.itemsSelected, account.id.toString())) {
                        this.accounts.push(account);
                    }
                })
                this.loading = false;
            },
            getSelectAccountsObjects() {
                let selectedAccounts = []
                _.forEach(this.accounts, account => {
                    if (_.includes(this.itemsSelected, account.name)) {
                        account.platform = this.selectedPlatform
                        selectedAccounts.push(account)
                    }
                })
                return selectedAccounts;
            }
        }
    }
</script>